<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE DETAILED ITEM RECEIPT LIST DATA</title>
    <section class="content-header">
      <h1>
        Update Detailed Item Receipt List Data
        <br />
        <h4>
          Please enter the transaction data for the detailed item receipt list
        </h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Items Receipt List Transaction Details</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Items Receipt List</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Receipt Number </label>
                  <input
                    type="text"
                    v-model="receiptno"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxpend"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxtpbbarangpend"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Item Code</label>
                    <input
                      type="text"
                      v-model="kodebarang"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Please enter Item Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getbarang()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="namabarang"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty </label>
                  <input
                    type="text"
                    v-model="qtypend"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit </label>
                  <input
                    type="text"
                    v-model="unitd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="notesd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Details of Items Receipt List</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">CIF </label>
                  <input
                    type="text"
                    readonly
                    v-model="cif"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label for="exampleInputEmail1">CIF Rupiah </label>
                  <input
                    type="text"
                    readonly
                    v-model="cifrupiah"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Invoice Price </label>
                  <input
                    type="text"
                    readonly
                    v-model="hrginvoice"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Submission Price</label>
                  <input
                    type="text"
                    readonly
                    v-model="hrgpenyerahan"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit Price </label>
                  <input
                    type="text"
                    readonly
                    v-model="hrgsatuan"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/finishgood">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      list_paged: [],
      loading: false,
      receiptno: "",
      kodebarang: "",
      namabarang: "",
      unitd: "",
      notesd: "",
      cif: "",
      cifrupiah: "",
      hrginvoice: "",
      hrgpenyerahan: "",
      hrgsatuan: "",
      qtypend: "",
      idxpend: ""
    };
  },
  created() {
    // console.log(this.$route.params.id);
    this.idxpend = this.$route.params.id;
    this.fetchdata();
  },
  methods: {
    getbarang() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbykode_barang?kode_barang=" + this.kodebarang;
      const urlAPIget =
        this.$apiurl +
        "barang/getbarangbykode_barang?kode_barang=" +
        this.kodebarang;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, item code not found",
              showConfirmButton: false
            });
            this.namabarang = "";
            this.loading = false;
          } else {
            this.kodebarang = resp.data.data.kode_barang;
            this.namabarang = resp.data.data.nama_barang;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    fetchdata() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/penerimaan_d/getpenerimaan_dbyid?length=1000&id_header=" + this.$route.params.id;

      const urlAPIget =
        this.$apiurl +
        "penerimaan_fg_d/getpenerimaan_fg_dbyid?id=" +
        this.$route.params.id;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          //   console.log(resp);
          this.idxpend = resp.data.data.id;
          this.receiptno = resp.data.data.receipt_number;
          this.kodebarang = resp.data.data.kode_barang;
          this.qtypend = resp.data.data.qty;
          this.notesd = resp.data.data.notes;
          this.unitd = resp.data.data.unit;
          this.cif = resp.data.data.cif;
          this.cifrupiah = resp.data.data.cif_rupiah;
          this.hrginvoice = resp.data.data.harga_invoice;
          this.hrgpenyerahan = resp.data.data.harga_penyerahan;
          this.hrgsatuan = resp.data.data.harga_satuan;
          this.idxtpbbarangpend = resp.data.data.id_tpb_barang;
          const urlAPIgetb =
            this.$apiurl +
            "barang/getbarangbykode_barang?kode_barang=" +
            this.kodebarang;
          axios.get(urlAPIgetb, { headers }).then((resp) => {
            if (resp.data.pesan == "Data not found") {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Sorry, item code not found",
                showConfirmButton: false
              });
              this.namabarang = "";
            } else {
              this.kodebarang = resp.data.data.kode_barang;
              this.namabarang = resp.data.data.nama_barang;
              this.loading = false;
            }
          });
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
            showConfirmButton: false
          });
          this.loading = false;
        });
    },
    updateData() {
      this.loading = true;
      var kodeuser = sessionStorage.getItem("kodeuser");
      const paramdata = {
        receipt_number: this.receiptno,
        kode_barang: this.kodebarang,
        notes: this.notesd,
        qty: this.qtypend,
        kode_user: kodeuser,
        unit: this.unitd,
        cif: this.cif,
        cif_rupiah: this.cifrupiah,
        harga_invoice: this.hrginvoice,
        harga_penyerahan: this.hrgpenyerahan,
        harga_satuan: this.hrgsatuan,
        id_tpb_barang: this.idxtpbbarangpend
      };
      console.log(paramdata);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };

      const urlAPIUpdate =
        this.$apiurl +
        "penerimaan_fg_d/updatepenerimaan_fg_d_external/" +
        this.idxpend;
      // console.log(urlAPIUpdate);
      axios
        .put(urlAPIUpdate, paramdata, { headers: headers })
        .then((respn) => {
          if (respn.data.status == true) {
            swal({
              icon: "success",
              title: "Success",
              text: "Update data successfully",
              showConfirmButton: false
            });
            this.loading = false;
            this.$router.push({ name: "finishgoodheader" });
          } else {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Update data failed",
              showConfirmButton: false
            });
            this.loading = false;
            this.$router.push({ name: "finishgoodheader" });
          }
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
            showConfirmButton: false
          });
          this.loading = false;
        });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
